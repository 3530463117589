import React from 'react';

class App extends React.Component {


  componentDidMount() {
    if (typeof window !== 'undefined') {
    window.location.href = "http://houseofjuke.splashthat.com/";
}
  }

  render() {

    return (
      <div className="App">
      </div>
    );
  }
}

export default App;